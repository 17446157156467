<template>
  <div class="app-container">
    <div class="filter-container">
      <div>
        订单号:
        <el-input v-model="listQuery.order_no" placeholder="请输入订单号" style="width: 200px" class="filter-item" clearable />

        <el-select v-model="listQuery.type" placeholder="状态" clearable style="width: 120px" class="filter-item">
          <el-option v-for="item in typeList" :key="item.type" :label="item.name" :value="item.type" />
        </el-select>

        <!-- <el-select
          v-model="listQuery.is_child_order_refund"
          placeholder="售后状态"
          clearable
          style="width: 120px"
          class="filter-item"
        >
          <el-option label="正常" :value="0" />
          <el-option label="待处理" :value="1" />
        </el-select> -->
        地址
        <el-select v-model="listQuery.province_code" placeholder="请选择省份" clearable style="width: 120px" class="filter-item" @change="getcityList()">
          <el-option v-for="item in provinceList" :key="item.code" :label="item.name" :value="item.code" />
        </el-select>
        <el-select v-model="listQuery.city_code" placeholder="请选择市区" clearable style="width: 120px" class="filter-item" @change="getareasList()">
          <el-option v-for="item in cityList" :key="item.code" :label="item.name" :value="item.code" />
        </el-select>
        <el-select v-model="listQuery.area_code" placeholder="请选择街道" clearable style="width: 120px" class="filter-item">
          <el-option v-for="item in areaList" :key="item.code" :label="item.name" :value="item.code" />
        </el-select>
        <!-- <el-input
          v-model="listQuery.address"
          placeholder="请输入地址"
          style="width: 200px"
          class="filter-item"
          clearable
        /> -->
        时间
        <el-date-picker
          v-model="dateArr"
          type="datetimerange"
          :default-time="['00:00:00', '23:59:59']"
          align="right"
          value-format="yyyy-MM-dd HH:mm:ss"
          unlink-panels
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :picker-options="pickerOptions"
          class="filter-item"
          style="display: inline-flex"
        ></el-date-picker>
        <el-input
          v-model="listQuery.contact"
          placeholder="请输入收件人"
          style="width: 200px"
          class="filter-item"
          clearable
        />
        <el-input
          v-model="listQuery.contact_phone"
          placeholder="请输入电话号码"
          style="width: 200px"
          class="filter-item"
          clearable
        />
        <el-button
          class="filter-item"
          type="primary"
          icon="el-icon-search"
          @click="handleFilter"
          >搜索</el-button
        >
      </div>
    </div>
    <el-table height="600" v-loading="listLoading" :data="list" element-loading-text="Loading" border fit highlight-current-row>
      <el-table-column align="center" label="序号" width="95">
        <template slot-scope="scope">
          {{ scope.$index + 1 }}
          <!-- {{ scope.row.id }} -->
        </template>
      </el-table-column>
      <el-table-column label="用户昵称" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.user.nickname }}
        </template>
      </el-table-column>
      <el-table-column label="头像" min-width="110" align="center">
        <template slot-scope="scope">
          <img :src="scope.row.user.avatar" class="user-avatar" v-if="scope.row.user" />
          <i v-else>-</i>
        </template>
      </el-table-column>
      <el-table-column label="订单编号" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.order_no }}
        </template>
      </el-table-column>
      <el-table-column label="商品名称" min-width="110" align="center">
        <template slot-scope="scope">
          {{ 
              JSON.parse(scope.row.product_snap)
              .name
           }}
        </template>
      </el-table-column>
      <el-table-column label="总价" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.total_price }}
        </template>
      </el-table-column>
      <el-table-column label="收件人" min-width="110" align="center">
        <template slot-scope="scope">
          {{
            JSON.parse(scope.row.parent_order.user_receiving_address_snap)
              .contact
          }}
        </template>
      </el-table-column>
      <el-table-column label="电话" min-width="110" align="center">
        <template slot-scope="scope">
          {{
            JSON.parse(scope.row.parent_order.user_receiving_address_snap)
              .contact_phone
          }}
        </template>
      </el-table-column>
      <el-table-column label="收货地址" min-width="110" align="center">
        <template slot-scope="scope">
          {{
            JSON.parse(scope.row.parent_order.user_receiving_address_snap)
              .province
              ? JSON.parse(scope.row.parent_order.user_receiving_address_snap)
                  .province.name
              : "-"
          }}
          {{
            JSON.parse(scope.row.parent_order.user_receiving_address_snap).city
              ? JSON.parse(scope.row.parent_order.user_receiving_address_snap)
                  .city.name
              : ""
          }}
          {{
            JSON.parse(scope.row.parent_order.user_receiving_address_snap).area
              ? JSON.parse(scope.row.parent_order.user_receiving_address_snap)
                  .area.name
              : ""
          }}
        </template>
      </el-table-column>
      <el-table-column label="详情地址" min-width="110" align="center">
        <template slot-scope="scope">
          {{
            JSON.parse(scope.row.parent_order.user_receiving_address_snap)
              .detail_address
          }}
        </template>
      </el-table-column>
      <el-table-column label="时间" min-width="170" align="center">
        <template slot-scope="scope">
          {{ scope.row.parent_order.pay_at || "-" }}
        </template>
      </el-table-column>
      <el-table-column label="物流单号" min-width="160" align="center">
        <template slot-scope="scope">
          {{ scope.row.logistics_no || "-" }}
        </template>
      </el-table-column>
      <el-table-column label="物流公司" min-width="100" align="center">
        <template slot-scope="scope">
          {{ scope.row.courier_company!=null?scope.row.courier_company.name:'-'  || "-" }}
        </template>
      </el-table-column>

      <!-- <el-table-column label="配送方式" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.total_price }}
        </template>
      </el-table-column> -->

      <el-table-column class-name="status-col" label="状态" min-width="110" align="center">
        <template slot-scope="scope">
          <template v-if="scope.row.refund_status != 1">
            <el-tag v-if="scope.row.status == 1" type="warning">待发货</el-tag>
            <el-tag v-else-if="scope.row.status == 2" type="success">已发货</el-tag>
            <el-tag v-else-if="scope.row.status == 3" type="success">已完成</el-tag>
            <el-tag v-else-if="scope.row.status == 4" type="danger">已退款</el-tag>
            <el-tag v-else-if="scope.row.status == 5" type="">售后中</el-tag>
            <el-tag v-else type="danger">已取消</el-tag>
          </template>
          <template v-else>
            <el-tag type="">售后中</el-tag>
          </template>
        </template>
      </el-table-column>
      <!-- <el-table-column
        class-name="status-col"
        label="退货状态"
        min-width="110"
        align="center"
      >
        <template slot-scope="scope">
          <el-tag
            v-if="scope.row.status != 5 && scope.row.has_child_order_refund > 0"
            type="danger"
          >
            有退款,待处理
          </el-tag>
          <p v-else>-</p>
        </template>
      </el-table-column> -->

      <el-table-column fixed="right" label="操作" align="center" width="230" class-name="small-padding fixed-width ">
        <template slot-scope="scope">
          <el-button class="button" type="primary" size="mini" @click="godetailst(scope.row.id)">
            订单详情
          </el-button>
          <el-button class="button" v-if="
              (scope.row.refund_status == 0 || scope.row.refund_status == 3) &&
              scope.row.status == 1
            " type="danger" size="mini" @click="
              shipmentsShow(scope.row.order_no, scope.row.distribution_price)
            ">
            发货
          </el-button>
          <el-button class="button" v-if="scope.row.refund_status == 1" type="danger" size="mini" @click="godetailst(scope.row.id)">
            处理售后
          </el-button>
          <el-button class="button" v-if="scope.row.logistics_no!=null" type="warning" size="mini" @click="goEdit(scope.row.logistics_no)">
            查看物流
          </el-button>
          <!--           
          <el-button
            class="button"
            v-else-if="scope.row.status != 4"
            type="success"
            size="mini"
            @click="refund(scope.row.order_no)"
          >
            退款
          </el-button> -->
          <el-button class="button" type="primary" size="mini" @click="copy(scope.row)">
            复制
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-dialog :visible.sync="copetype" width="30%">
      <span style="display:block;margin:25px">电话：{{copylist.user.phone}}</span>
      <span style="display:block;margin:20px 0">订单编号：{{copylist.parent_order_no}}</span>
      <span style="margin:25px">地址：{{copylist.address}}{{copylist.address2}}{{copylist.address3}}</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="copetype = false">取 消</el-button>
        <el-button type="primary" @click="copetype = false">确 定</el-button>
      </span>
    </el-dialog>

    <div class="pagination-container">
      <el-pagination :current-page="listQuery.page" :page-sizes="[10, 20, 30, 50]" :page-size="listQuery.per_page" :total="total" background
        layout="total, sizes, prev, pager, next, jumper" @size-change="handleSizeChange" @current-change="handleCurrentChange" />
    </div>

    <el-dialog title="确认发货" :visible.sync="shipmentsVisible" :close-on-click-modal="false">
      <el-form ref="shipmentsData" :rules="shipmentsrules" :model="shipmentsData" label-width="120px">
        <el-form-item label="订单号" prop="order_no">
          <!-- <el-input type="text" v-model="form.title" clearable /> -->
          {{ shipmentsData.order_no }}
        </el-form-item>
        <el-form-item label="物流公司" prop="courier_company_id">
          <el-select v-model="shipmentsData.courier_company_id" placeholder="请选择公司" clearable style="width: 120px" class="filter-item">
            <el-option v-for="item in logisticsCompany" :key="item.id" :label="item.name" :value="item.id" />
          </el-select>
        </el-form-item>
        <el-form-item label="物流单号" prop="logistics_no">
          <el-input type="text" v-model="shipmentsData.logistics_no" clearable />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="shipmentsVisible = false">取消</el-button>
        <el-button type="primary" @click="shipments" :loading="btnLoading">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import request from '@/utils/request'

export default {
  filters: {
    statusFilter(status) {
      const statusMap = {
        published: 'success',
        draft: 'gray',
        deleted: 'danger',
      }
      return statusMap[status]
    },
  },
  data() {
    return {
      copetype: false,
      copylist: {
        user: { phone: '' },
        parent_order_no: '',
        address: '',
        address2: '',
        address3: '',
      },
      list: null,
      dateArr: null,
      twolist: [],
      provinceList: [],
      cityList: [],
      areaList: [],
      logisticsCompany: [],
      total: null,
      listLoading: false,
      shipmentsVisible: false,
      btnLoading: false,
      listQuery: {
        page: 1,
        per_page: 10,
        nickname: '',
        order_no: '',
        type: '',
        start_datetime: '',
        end_datetime: '',
        area_code: '',
        city_code: '',
        province_code: '',
      },
      start_datetime: '',
      end_datetime: '',
      fa_system_list: [],
      //发货
      shipmentsData: {
        order_no: '',
        logistics_no: '',
        // logistics_company: "",
      },
      pickerOptions: {
        shortcuts: [
          {
            text: '最近一周',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', [start, end])
            },
          },
          {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
              picker.$emit('pick', [start, end])
            },
          },
          {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
              picker.$emit('pick', [start, end])
            },
          },
        ],
      },
      typeList: [
        { name: '待发货', type: 1 },
        { name: '已发货', type: 2 },
        { name: '已完成', type: 3 },
        { name: '售后中', type: 4 },
        { name: '售后成功', type: 5 },
        { name: '售后取消', type: 6 },
      ],
      shipmentsrules: {
        logistics_no: [
          { required: true, message: '物流单号不能为空', trigger: 'change' },
        ],
        courier_company_id: [
          { required: true, message: '物流公司不能为空', trigger: 'change' },
        ],
      },
    }
  },
  created() {
    this.getList()
    this.getlogisticsCompany()
    this.getprovinceList()
  },
  watch: {
    dateArr(newVal, oldVal) {
      if (newVal) {
        this.listQuery.start_at = newVal[0]
        this.listQuery.end_at = newVal[1]
      } else {
        this.listQuery.start_at = ''
        this.listQuery.end_at = ''
      }
    },
  },
  methods: {
    copy(res) {
      //   this.copetype = true

      this.copylist = res
      this.copylist.address = JSON.parse(
        this.copylist.parent_order.user_receiving_address_snap
      ).province.name
      this.copylist.address2 = JSON.parse(
        this.copylist.parent_order.user_receiving_address_snap
      ).city.name
      this.copylist.address3 = JSON.parse(
        this.copylist.parent_order.user_receiving_address_snap
      ).area.name

      let name = JSON.parse(this.copylist.parent_order.user_receiving_address_snap)
              .contact

      let addres4 = JSON.parse(this.copylist.parent_order.user_receiving_address_snap)
              .detail_address 
              
    //   console.log(addres4.match('县').index);
    //   console.log(addres4.substr((addres4.match('县').index)+1));


      let txa = document.createElement('textarea')
      let txval =
        name +
        '\n' +
        this.copylist.user.phone +
        '\n' +
        this.copylist.address +
        this.copylist.address2 +
        this.copylist.address3  + addres4
      // console.log('copy val:', txval)
      txa.value = txval
      document.body.appendChild(txa)
      txa.select()
      let rea = document.execCommand('copy')
      document.body.removeChild(txa)

      this.$message({
        type: 'success',
        message: '复制成功!',
      })
    },
    getprovinceList() {
      request({
        url: '/api/common/china/provinces',
        method: 'get',
      }).then((response) => {
        this.provinceList = response.data
        this.cityList = []
        if (this.listQuery.city_code) {
          this.listQuery.city_code = ''
        }
      })
    },
    getcityList() {
      if (!this.listQuery.province_code) {
        this.cityList = []
        return
      }
      request({
        url: '/api/common/china/cities',
        method: 'get',
        params: { province_code: this.listQuery.province_code },
      }).then((response) => {
        this.cityList = response.data
        this.areaList = []
        if (this.listQuery.area_code) {
          this.listQuery.area_code = ''
        }
      })
    },
    getareasList() {
      if (!this.listQuery.city_code) {
        this.areaList = []
        return
      }
      request({
        url: '/api/common/china/areas',
        method: 'get',
        params: { city_code: this.listQuery.city_code },
      }).then((response) => {
        this.areaList = response.data
      })
    },

    goEdit(order) {
    //   let url = `https://m.kuaidi100.com/app/query/?nu=${order}`
    //   window.location.href = url

        let url = this.$router.resolve({
            path: 'https://m.kuaidi100.com/app/query',
            query: {nu: order}
        })
        window.open(url.href.substr(7), '_blank')



      //   this.$router.push(`https://m.kuaidi100.com/app/query/?nu=${order}`);
    },
    shipmentsShow(order, price) {
      this.shipmentsData = {
        order_no: order,
        logistics_no: '',
        courier_company_id: '',
      }

      this.shipmentsVisible = true
      // if (price > 0) {
      // } else {
      //   this.$confirm("此操作将人工发货给该用户, 是否继续?", "提示", {
      //     confirmButtonText: "确定",
      //     cancelButtonText: "取消",
      //     type: "warning",
      //   })
      //     .then(() => {
      //       this.shipments();
      //     })
      //     .catch(() => {
      //       this.$message({
      //         type: "info",
      //         message: "已取消",
      //       });
      //     });
      // }
    },
    shipments() {
      this.$refs['shipmentsData'].validate((valid) => {
        if (valid) {
          this.shipmentsVisible = true
          request({
            url: `/api/backend/productOrder/send`,
            method: 'post',
            data: this.shipmentsData,
          }).then(() => {
            this.getList()
            this.shipmentsVisible = false
            this.$message({
              type: 'success',
              message: '操作成功!',
            })
          })
        }
      })
    },
    clear() {
      this.listQuery.fa_system_id = ''
      this.twolist = []
      this.$forceUpdate() //强制更新
    },
    refund(val) {
      this.$confirm('此操作将退款给该用户, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          request({
            url: '/api/backend/user/forbid',
            method: 'get',
            params: { id: val },
          }).then(() => {
            this.getList()
            this.$message({
              type: 'success',
              message: '操作成功!',
            })
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消',
          })
        })
    },
    getlogisticsCompany() {
      request({
        url: '/api/backend/productOrder/courierCompany',
        method: 'get',
      }).then((response) => {
        this.logisticsCompany = response.data
        console.log(this.logisticsCompany,'快递公司');
        
        
      })
    },
    getList() {
      this.listLoading = true
      this.listQuery.start_datetime = this.start_datetime
        ? this.start_datetime + ' 00:00:00'
        : ''
      this.listQuery.end_datetime = this.end_datetime
        ? this.end_datetime + ' 23:59:59'
        : ''
      request({
        url: '/api/backend/productOrder/list',
        method: 'get',
        params: this.listQuery,
      }).then((response) => {
        this.list = response.data.data
        console.log(this.list, '============this.list')
        this.total = response.data.total
        this.listLoading = false
      })
    },
    godetailst(order) {
      this.$router.push('/order/product_details?order=' + order)
    },
    handleFilter() {
      this.listQuery.page = 1
      this.getList()
    },
    handleSizeChange(val) {
      this.listQuery.per_page = val
      this.getList()
    },
    handleCurrentChange(val) {
      this.listQuery.page = val
      this.getList()
    },
    upDown(id) {
      request({
        url: `/api/backend/faSystem/linkUpDown`,
        method: 'post',
        data: {
          id: id,
        },
      }).then(() => {
        this.getList()
        this.$message({
          type: 'success',
          message: '操作成功!',
        })
      })
    },
    handleForbid(val, text) {
      this.$confirm('此操作将' + text + '该用户, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          request({
            url: '/api/backend/user/forbid',
            method: 'get',
            params: { id: val },
          }).then(() => {
            this.getList()
            this.$message({
              type: 'success',
              message: '操作成功!',
            })
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消',
          })
        })
    },
  },
}
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.user-avatar {
  width: 40px;
  height: 40px;
  border-radius: 6px;
}
.button:first-child {
  margin-left: 10px;
}
.button {
  margin-bottom: 10px;
}
</style>
